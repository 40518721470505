jQuery(document).ready(function ($) {
  // Function to update the URL with the selected category
  function updateUrl(category) {
    var urlParams = new URLSearchParams(window.location.search);
    urlParams.set("category", category); // Set or update the category parameter
    history.pushState(
      null,
      null,
      window.location.pathname + "?" + urlParams.toString()
    );
  }

  // Function to set the category filter from URL on page load
  function setFiltersFromUrl() {
    var urlParams = new URLSearchParams(window.location.search);
    var category = urlParams.get("category");

    if (category) {
      // Remove "active" class from all <li> elements
      $("#blog-filter li").removeClass("active");

      // Add "active" class to the relevant <li>
      $("#blog-filter a[data-category='" + category + "']")
        .closest("li")
        .addClass("active");

      // Trigger the AJAX request to filter posts based on the category
      $.ajax({
        type: "POST",
        url: ajax_object.ajaxurl, // Use localized ajaxurl
        data: {
          action: "filter_posts",
          category: category,
          nonce: ajax_object.nonce, // Add the nonce
        },
        success: function (response) {
          $("#send-posts-blog").html(response);
          console.log("Posts filtered successfully");
        },
        error: function (xhr, status, error) {
          console.error("AJAX Error:", error); // Log AJAX error
        },
      });
    }
  }

  // Call setFiltersFromUrl on page load
  setFiltersFromUrl();

  $("#blog-filter a").click(function (e) {
    e.preventDefault();

    // Remove "active" class from all <li> elements
    $("#blog-filter li").removeClass("active");

    // Add "active" class to the clicked <li>
    $(this).closest("li").addClass("active");
    var category = $(this).data("category");

    // Update URL with the selected category
    updateUrl(category);

    // Fetch and display filtered posts
    $.ajax({
      type: "POST",
      url: ajax_object.ajaxurl, // Use localized ajaxurl
      data: {
        action: "filter_posts",
        category: category,
        nonce: ajax_object.nonce, // Add the nonce
      },
      success: function (response) {
        $("#send-posts-blog").html(response);
        console.log("Posts filtered successfully");
      },
      error: function (xhr, status, error) {
        console.error("AJAX Error:", error); // Log AJAX error
      },
    });
  });

  // Handle back and forward button navigation
  window.onpopstate = function () {
    setFiltersFromUrl();
  };
});
