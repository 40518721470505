(function ($) {
  var ajaxurl = ajax_object.ajaxurl;
  var page = 1;
  var loading = false;
  var debounceTimeout;

  // Initialize filters from URL on page load
  function setFiltersFromUrl() {
    var urlParams = new URLSearchParams(window.location.search);
    urlParams.forEach(function (value, key) {
      var elements = $("#filter-form").find(
        "[name='" + key + "'], [name='" + key + "[]']"
      );
      if (elements.length > 0) {
        if (elements.is(":checkbox")) {
          elements
            .filter("[value='" + decodeURIComponent(value) + "']")
            .prop("checked", true)
            .siblings("a.checkbox-toggle")
            .addClass("selected");
        } else {
          elements.val(decodeURIComponent(value));
        }
      }
    });
  }

  // Function to update URL with filters
  function updateUrlParams(filteredData) {
    var urlParams = new URLSearchParams(window.location.search);

    $.each(filteredData, function (key, value) {
      if (Array.isArray(value)) {
        value.forEach(function (v) {
          urlParams.append(key + "[]", encodeURIComponent(v)); // Use [] for array parameters
        });
      } else {
        urlParams.set(key, encodeURIComponent(value));
      }
    });

    // Exclude nonce and _wp_http_referer parameters from the URL
    ["nonce", "_wp_http_referer"].forEach(function (key) {
      urlParams.delete(key);
    });

    var newUrl = window.location.pathname + "?" + urlParams.toString();
    if (window.location.href !== newUrl) {
      history.pushState(null, null, newUrl);
    }
  }

  // Main function to filter posts
  function filterPosts() {
    var formDataArray = $("#filter-form").serializeArray();
    var filteredData = {};

    $.each(formDataArray, function (index, obj) {
      if (
        obj.name !== "nonce" &&
        obj.name !== "_wp_http_referer" &&
        obj.value !== "undefined" &&
        obj.value !== ""
      ) {
        var cleanName = obj.name.replace(/\[\]$/, ""); // Remove [] from the name
        if (filteredData[cleanName]) {
          if (!Array.isArray(filteredData[cleanName])) {
            filteredData[cleanName] = [filteredData[cleanName]];
          }
          filteredData[cleanName].push(obj.value);
        } else {
          filteredData[cleanName] = obj.value;
        }
      }
    });

    $.ajax({
      type: "POST",
      url: ajaxurl,
      data: $.param(filteredData) + "&action=filter_reports&page=" + page,
      success: function (response) {
        if (page === 1) {
          $("#send-posts").html(response);
        } else {
          console.log("Loading more posts");
        }
        loading = false;
        updateUrlParams(filteredData); // Update URL with filters
      },
      error: function (xhr, status, error) {
        console.error("AJAX Error:", error);
        loading = false;
      },
    });
  }

  function debounce(func, wait) {
    return function () {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(func, wait);
    };
  }

  $(document).ready(function () {
    setFiltersFromUrl(); // Set filters based on URL
    filterPosts(); // Fetch posts on initial load

    $("#view-all").click(function (e) {
      e.preventDefault();
      $("input[type='checkbox']").prop("checked", false);
      $("#filter-form").submit();
    });

    $("#filter-form").submit(function (e) {
      e.preventDefault();
      page = 1; // Reset page to 1 on new filter submission
      filterPosts();
    });

    $("#filter-form").on(
      "change",
      "input[type='checkbox']",
      debounce(function () {
        $(this)
          .siblings("a.checkbox-toggle")
          .toggleClass("selected", this.checked);
        $("#filter-form").submit();
      }, 300)
    ); // Debounce the submit

    $(window).scroll(function () {
      var container = $("#send-posts");
      var scrollTop = $(window).scrollTop();
      var containerTop = container.offset().top;
      var containerHeight = container.outerHeight();
      var windowHeight = $(window).height();

      if (
        scrollTop + windowHeight >= containerTop + containerHeight - 200 &&
        !loading
      ) {
        loading = true;
        page++;
        filterPosts();
      }
    });

    $("#filter-toggle-btn").click(function () {
      $("#report-filter").slideToggle();
    });

    // Handle back and forward button navigation
    window.onpopstate = function () {
      setFiltersFromUrl();
      filterPosts();
    };
  });
})(jQuery);
